export default abstract class WaesAPI {

    constructor(protected serviceBaseUrl: string) {}

    getAPIBaseUrl(): string {
        return `${this.serviceBaseUrl}/api/v${this.getVersion()}`;
    }

    protected getRequestParams = (token: string) => {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: false,
        };
    }

    abstract getVersion(): number;
}
