import type { Services } from "../../../common";
import type { Tokens } from "../../../common/routes/services";

import { request } from "./request";

export const apiServiceCreateToken = (
  params: Tokens.CreateBody<Services.Name>
) => {
  return request<Tokens.CreateResponse>(
    "post",
    `/services/tokens/create`,
    params
  );
};
