import type { AccessGrants } from '../../../common/routes/access-grants';
import type { Dispatch } from '@reduxjs/toolkit';

import {
    apiApproveAccessGrant,
    apiGetAccessGrants,
    apiGetAuthenticationUrl,
    apiPatchAccessGrant,
    apiPostAccessGrant,
    apiRejectAccessGrant,
    apiRevokeAccessGrant,
} from '@/api/accessGrants.api';

import { Customer } from '../../../common/customer';
import { addAccessGrant, setAccessGrants, updateAccessGrant } from './accessGrants.store';

export const loadAccessGrantsAsync = (query?: AccessGrants.ListQuery) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetAccessGrants(query);

        if (data) {
            dispatch(setAccessGrants(data));
        }
    };
};

export const createAccessGrantAsync = (grant: AccessGrants.PostBody) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiPostAccessGrant(grant);

        if (data) {
            dispatch(addAccessGrant(data));

            return true;
        }

        return false;
    };
};

export const updateAccessGrantAsync = (grant: AccessGrants.PatchBody, id: string) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiPatchAccessGrant(grant, id);

        if (data) {
            dispatch(updateAccessGrant(data));

            return data;
        }

        return null;
    };
};

export const revokeAccessGrantAsync = (uid: string) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiRevokeAccessGrant(uid);

        if (data) {
            dispatch(updateAccessGrant(data));

            return data;
        }

        return null;
    };
};

export const approveAccessGrantAsync = (uid: string, expires: number) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiApproveAccessGrant(uid, expires);

        if (data) {
            dispatch(updateAccessGrant(data));

            return data;
        }

        return null;
    };
};

export const rejectAccessGrantAsync = (uid: string, reason?: string) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiRejectAccessGrant(uid, reason);

        if (data) {
            dispatch(updateAccessGrant(data));

            return data;
        }

        return null;
    };
};

export const discardAccessGrantAsync = (uid: string) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiPatchAccessGrant({ status: Customer.AccessGrantStatus.discarded }, uid);

        if (data) {
            dispatch(updateAccessGrant(data));

            return data;
        }

        return null;
    };
};

export const getAuthenticationUrlAsync = (grantId: string) => {
    return async () => {
        const { data } = await apiGetAuthenticationUrl(grantId);

        if (data) {
            return data.url;
        }

        return null;
    };
};
