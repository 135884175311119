
import type { Facebook } from '../../../../../../common/routes/channels';
import type { FC } from 'react';

import './index.less';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MyInput from '@/components/basic/input';
import MyForm from '@/components/core/form';
import MyModal from '@/components/core/modal';
import { LocaleFormatter, useLocale } from '@/locales';
import { addFacebookUserTokenAsync, editFacebookUserTokenAsync } from '@/stores/channels.action';

type FormDataProps<IsEdit = boolean> = IsEdit extends true ? Facebook.Tokens.PatchBody : Facebook.Tokens.AddBody;

interface Props {
    visible: boolean;
    onCancel: () => void;
    editTokenId?: string;
}

const AddEditTokenModal: FC<Props> = ({ visible, onCancel, editTokenId }) => {
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useLocale();
    const dispatch = useDispatch();

    const initialValues: FormDataProps = {
        value: '',
        notes: '',
    };

    const onClose = async (formData?: FormDataProps) => {
        if (formData) {
            try {
                setLoading(true);
                let success = false;

                if (editTokenId) {
                    success = Boolean(
                        await dispatch(
                            editFacebookUserTokenAsync({
                                id: editTokenId,
                                body: formData as FormDataProps<true>,
                            }),
                        ),
                    );
                } else {
                    success = Boolean(await dispatch(addFacebookUserTokenAsync(formData as FormDataProps<false>)));
                }

                if (success) {
                    onCancel();
                }
            } finally {
                setLoading(false);
            }
        } else {
            onCancel();
        }
    };

    return (
        <MyModal
            form={initialValues}
            formProps={{ layout: 'vertical', preserve: false, initialValues }}
            title={<LocaleFormatter id={`facebook.addTokenModal.${editTokenId ? 'titleEdit' : 'title'}`} />}
            open={visible}
            onClose={onClose}
            okText={<LocaleFormatter id={`facebook.addTokenModal.${editTokenId ? 'titleEdit' : 'title'}`} />}
            confirmLoading={loading}
            width={600}
            destroyOnClose
        >
            {!editTokenId && (
                <MyForm.Item
                    label={formatMessage({
                        id: 'facebook.addTokenModal.tokenValue',
                    })}
                    name="value"
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'facebook.addTokenModal.tokenValue.required',
                            }),
                        },
                    ]}
                >
                    <MyInput
                        placeholder={formatMessage({
                            id: 'facebook.addTokenModal.tokenValue.placeholder',
                        })}
                    />
                </MyForm.Item>
            )}
            <MyForm.Item
                label={formatMessage({
                    id: 'facebook.addTokenModal.tokenNotes',
                })}
                name="notes"
            >
                <MyInput />
            </MyForm.Item>
        </MyModal>
    );
};

export default AddEditTokenModal;
