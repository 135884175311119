import type { Facebook } from "../../../../../../common/routes/channels";
import type { FC } from "react";

import "./index.less";

import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import MyForm from "@/components/core/form";
import MyFormItem from "@/components/core/form-item";
import { LocaleFormatter, } from "@/locales";

interface Props {
  onSubmit: (registerBody: Facebook.WhatsApp.PhoneNumber.RegisterBody) => void;
}

const RegisterPhoneNumberForm: FC<Props> = ({ onSubmit }) => {
  const [form] = MyForm.useForm();

  const onFormSubmit = async () => {
      await form.validateFields();
      const registerBody = form.getFieldsValue();

      onSubmit(registerBody);
  };

  return (
    <MyForm
      form={form}
      layout="vertical"
      className="register-phone-number-form"
      scrollToFirstError
    >
      <MyFormItem
        name="accessToken"
        label="Access Token"
        rules={[{ required: true }]}
      >
        <MyInput />
      </MyFormItem>
      <MyFormItem name="id" label="ID" rules={[{ required: true }]}>
        <MyInput />
      </MyFormItem>

      <MyFormItem name="pin" label="PIN" rules={[{ required: true }]}>
        <MyInput />
      </MyFormItem>
      <MyButton
        type="primary"
        htmlType="submit"
        size="large"
        onClick={onFormSubmit}
        className="register-button"
      >
        <LocaleFormatter id="global.register" />
      </MyButton>
    </MyForm>
  );
};

export default RegisterPhoneNumberForm;
