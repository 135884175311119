
export namespace Notifications {
    export interface Notification<IsJSON extends boolean = false, IDType extends any = string> {
        _id: IDType;
        /**
         * Title of the notification in the form of i18next key
         */
        titleI18n: string;
        /**
         * Fallback title of the notification if i18next key is not found
         */
        titleFallback: string;
        /**
         * Body of the notification in the form of i18next key
         */
        bodyI18n: string;
        /**
         * Fallback body of the notification if i18next key is not found
         */
        bodyFallback: string;
        /**
         * Data used with title and body when rendering with i18next
         */
        data?: {
            [key: string]: any;
        },
        severity: Severity;
        /**
         * Date when notification was created
         */
        createdAt: IsJSON extends true ? string : Date;
        /**
         * Optional image URL to display with notification
         */
        image?: string;
    }
    
    export enum Severity {
        INFO = 'info',
        WARNING = 'warning',
        ERROR = 'error',
        SUCCESS = 'success',
    }
}
