import type { Customers } from '../../../../common/routes/customers';
import type { AlertProps } from 'antd';

import './index.less';

import { Alert, List, message, Tag, Typography } from 'antd';
import classnames from "classnames";
import { type FC, useEffect, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import MySelect from '@/components/basic/select';
import MyForm from '@/components/core/form';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { LocaleFormatter, useLocale } from '@/locales';
import {
	getCustomerMovingRequirementsAsync,
	moveCustomerAsync,
} from '@/stores/customers.action';
import { hasPermissions } from '@/stores/user.action';

import { Instance, Permissions } from '../../../../common';

const MoveCustomerPage: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useLocale();
	const { instances } = useSelector((state) => state.instances);
	const navigate = useNavigate();

	const [toInstanceId, setToInstanceId] = useState<string>('');
	const [requirements, setRequirements] =
		useState<Customers.Move.GetResponse>();
	const [alertData, setAlertData] = useState<AlertProps | null>(null);

	const hasMigrationsPermission = dispatch(
		hasPermissions([Permissions.Permission.customersMigrateVersions])
	);

	const { instanceId, customerId } = useParams<{
		instanceId: string;
		customerId: string;
	}>();

	const instancesWithoutCurrent = instances.filter((i) => i._id !== instanceId);

	const onMoveCustomer = async () => {
		if (!instanceId || !customerId || !toInstanceId) return;

		const success = await dispatch(
			moveCustomerAsync(instanceId, customerId, toInstanceId)
		);

		if (success) {
			message.success(`Started moving ${customerId} to ${toInstanceId}`, 5);
			navigate('/customers');
		}
	};

	useAsyncEffect(async () => {
		if (!instanceId || !customerId) return;

		if (toInstanceId) {
			/* Fetch requirements */
			const requirements = await dispatch(
				getCustomerMovingRequirementsAsync(instanceId, customerId, toInstanceId)
			);

			setRequirements(requirements);
		}
	}, [toInstanceId, instanceId, customerId]);

	useEffect(() => {
		if (requirements) {
			let alertData: AlertProps | null = null;

			if (requirements.disallowed) {
				alertData = {
					message: formatMessage({ id: 'customers.move.disallowed' }),
					description: requirements.disallowed,
					type: 'error',
					action: (
						<MyButton
							size="large"
							type="primary"
							onClick={() => navigate('/customers')}
						>
							Go back
						</MyButton>
					),
				};
			} else if (requirements.migrationsType) {
				if (requirements.migrationsType !== 'none' && !hasMigrationsPermission) {
					alertData = {
						message: formatMessage({ id: 'customers.move.permissionRequired' }),
						description: formatMessage(
							{
								id: 'customers.move.permissionRequiredDescription',
							},
							{
								permission: (
									<strong>"{Permissions.permissionsInfo[Permissions.Permission.customersMigrateVersions].title}"</strong>
								),
							}
						),
						type: 'error',
						action: (
							<MyButton
								size="large"
								type="primary"
								onClick={() => navigate('/customers')}
							>
								Go back
							</MyButton>
						),
					};
				}

				if (requirements.migrationsType === 'forward') {
					alertData = null;
				}
			} else {
				alertData = null;
			}

			setAlertData(alertData);
		}
	}, [requirements]);

	return (
		<div className="container">
			<div className="title">
				<h1>
					<LocaleFormatter id="customers.move.title" values={{ customerId }} />
				</h1>
			</div>
			<div className="move-content">
				<MyForm
					layout="vertical"
					initialValues={{
						selectedInstance: instanceId,
					}}
				>
					<MyForm.Item label="Current Instance" name="selectedInstance">
						<Typography.Text className="move-instance-id">
							{instanceId}
						</Typography.Text>
					</MyForm.Item>
					<MyForm.Item
						label="Move to"
						name="availableInstances"
						extra={
              instancesWithoutCurrent.length === 0
                ? formatMessage({ id: "customers.move.noAvailableInstances" })
                : undefined
            }
					> 
						{/* TODO: Make shared component to select instances */}
						<MySelect 
							value={instanceId} 
							onChange={setToInstanceId} 
							disabled={instancesWithoutCurrent.length === 0}
						>
							{instancesWithoutCurrent.map((instance) => (
								<MySelect.Option 
									key={instance._id} 
									value={instance._id} 
									disabled={!Instance.Restrictions.canAcceptCustomer(instance, instance.customersCount).allowed}

								>
									{instance._id} ({instance.customersCount})
								</MySelect.Option>
							))}
						</MySelect>
					</MyForm.Item>

					{alertData ? (
						<Alert {...alertData} showIcon />
					) : (
						<>
							<MyForm.Item
								label="Migrations"
								name="migrations"
								hidden={!requirements?.migrations?.length}
							>
								<List
									itemLayout="horizontal"
									className="migrations-list"
									dataSource={requirements?.migrations}
									bordered
									renderItem={(item) => (
										<List.Item className={classnames({'non-reversible': !item.reversible})}>
											<List.Item.Meta
												avatar={
													requirements?.migrationsType === 'forward' ? (
														<AiOutlineUp color="green" />
													) : (
														<AiOutlineDown color="red" />
													)
												}
												title={`Version: ${item.version}`}
												description={
													<div className="migration-info">
														<span>Database collections change: </span>
														<strong>{ item.collections?.join(', ') }</strong>
													</div>
												}
											/>
											{!item.reversible && (
												<Tag color="red">NON-REVERSIBLE</Tag>
											)}
										</List.Item>
									)}
								/>
							</MyForm.Item>
							<MyForm.Item hidden={!requirements?.migrationsType}>
								<MyButton
									type="primary"
									htmlType="submit"
									size="large"
									disabled={!toInstanceId || !customerId || !instanceId}
									onClick={onMoveCustomer}
									className="start-move-customer"
								>
									<LocaleFormatter
										id="customers.move.moveCustomer"
										values={{
											customerId,
											instanceId: toInstanceId,
											upgrade: !requirements?.migrations?.length
												? ''
												: requirements.migrationsType === 'forward'
												? ' and upgrade'
												: ' and downgrade',
											b: (chunk: string) => <strong> {chunk} </strong>,
										}}
									/>
								</MyButton>
							</MyForm.Item>
						</>
					)}
				</MyForm>
			</div>
		</div>
	);
};

export default MoveCustomerPage;
