/**
 * Calculates the time left until the expiration of a JWT.
 * 
 * @param jwt - The JSON Web Token as a string.
 * @returns The number of milliseconds left until expiration.
 *          Returns 0 if the expiration is in the past, or -1 if there is no expiration.
 * @throws Error if the token cannot be decoded or the expiration field is invalid.
 */
export function getJwtExpirationTimeLeft(jwt: string): number {
    if (!jwt) {
        throw new Error("Invalid JWT: Token cannot be empty");
    }

    const parts = jwt.split('.');

    if (parts.length !== 3) {
        throw new Error("Invalid JWT: Token does not have the correct format");
    }

    try {
        // Decode the payload (second part of the JWT)
        const payload = JSON.parse(atob(parts[1]));

        // Check if the "exp" field exists
        if (payload.exp === undefined) {
            return -1; // No expiration field
        }

        // https://www.rfc-editor.org/rfc/rfc7519#section-4.1.4
        if (typeof payload.exp !== 'number') {
            throw new Error("Invalid JWT: 'exp' field is not a number");
        }

        // Calculate time left in milliseconds
        const expirationTime = payload.exp * 1000; // Convert seconds to milliseconds
        const currentTime = Date.now();

        return Math.max(0, expirationTime - currentTime);
    } catch (error) {
        throw new Error(`Failed to decode JWT: ${error instanceof Error ? error.message : String(error)}`);
    }
}
