import type { FC } from "react";

import "./index.less";

const WhatsappPage: FC = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <span className="subtitle whatsapp-subtitle">Whatsapp</span>
        </h1>
        <div className="title-actions"></div>
      </div>
      <div className="content"></div>
    </div>
  );
};

export default WhatsappPage;
