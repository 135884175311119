export interface WrapperProps {
  children: React.ReactNode;
  position: "left" | "right";
  order?: 0 | 1 | 2;
}

const Wrapper = ({ children, position, order = 0 }: WrapperProps) => {
  const moveBy = order ? order * 250 + (16 * order) : 0;

  return (
    <div
      className="action-header"
      style={{
        [position]: 48 + moveBy,
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
