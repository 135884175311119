import type { Metrics } from '../../../../../common';

import { useEffect,useState } from 'react';

import { apiGetChannelsCountByType } from '@/api/metrics/channels.api';
import { useLocale } from '@/locales';

export function AllChannelsCount() {

    const { formatMessage } = useLocale();
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState<Metrics.Channels.ByTypeCount | null>(null);

    useEffect(() => {
        setLoading(true);
        apiGetChannelsCountByType()
            .then(({data, status}) => {
                if (data) {
                    setMetrics(data);
                } else {
                    console.error('Failed to get channels by type customers metrics. Status: ', status);
                }
            })
            .catch((error) => {
                console.error('Failed to get channels by type customers metrics. Error: ', error);
            })
            .finally(() => setLoading(false));

    }, []);

    if (loading || !metrics) {
        return null;
    }

    return (
        <div>
            <h4>{formatMessage({ id: 'metrics.channels.allChannelsCount.title' })}</h4>
            <div>
                { Object.entries(metrics).sort((a, b) => b[1] - a[1]).map(([key, value]) => `${key}: ${value}`).join(', ') }
            </div>
        </div>
    );
}
