import type { AxiosError } from 'axios';

import { message as $message } from 'antd';

import { BACKEND_URL } from '@/environment';
import { CustomIcon } from '@/pages/layout/customIcon';
import { history } from '@/routes/history';

export const responseErrorHandler = (error: AxiosError) => {
    const status = error?.response?.status || 500;
    const data = error?.response?.data;
    const isClosable = status >= 500;
    const duration = isClosable ? 0 : 5;

    let errorMessage = 'Unknown error';

    if (error?.message?.includes('Network Error')) {
        errorMessage = 'Network Error, please check your network';
    } else if (status === 401) {
        errorMessage = 'Unauthorized';

        // Redirect to login page only if request was made to backend.
        // Condition is to prevent redirecting to login page when request was made to other services.
        if(error?.response?.config?.url?.startsWith(BACKEND_URL)){
            history.replace('/login');
        }
    } else if (status === 403) {
        errorMessage = 'Access Denied';
    } else if (status === 400) {
        errorMessage = 'Invalid request';

        if (data && typeof data.error === 'string') {
            errorMessage = data.error;
        }
    } else if (status >= 500) {
        if (data && typeof data.error === 'string') {
            errorMessage = data.error;
        } else {
            errorMessage = 'Internal server error';
        }
    }

    console.dir ? console.dir(error) : console.error('Request error', error);

    errorMessage &&
        $message.error({
            key: 'errorMessage',
            content: (
                <div style={{ display: 'inline-flex', alignItems: 'center', gap: 12 }}>
                    <span>{errorMessage}</span>
                    {isClosable && (
                        <CustomIcon
                            type="close"
                            style={{ cursor: 'pointer' }}
                            onClick={() => $message.destroy('errorMessage')}
                        />
                    )}
                </div>
            ),
            duration,
        });

    return errorMessage;
};
