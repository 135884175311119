export const enUS_customerMetrics = {
  "customerMetrics.title": "Customer Metrics",
  "customerMetrics.agentsMessagesCount": "Agents messages count",
  'customerMetrics.table.header.email': 'Email',
  'customerMetrics.table.header.sessionMessages': 'Session messages',
  'customerMetrics.table.header.templateMessages': 'Template messages',
  'customerMetrics.table.header.totalMessages': 'Total messages',
  'customerMetrics.table.header.channels': 'Channels',
  'customerMetrics.table.header.perChannel': 'Total messages per channel',
  'customerMetrics.table.header.accountId': 'Account ID',
  'customerMetrics.table.header.channel': 'Channel',
  'customerMetrics.table.header.count': 'Count',
};
