/**
 * Function to remove objects recursively in case it is empty
 *
 * @param obj - Object to remove empty objects from
 * @returns - Object with empty objects removed
 */
const removeEmptyObjects = (obj: {
  [key: string]: any;
}): { [key: string]: any } | null => {
  if (Array.isArray(obj)) {
    return obj
      .map(removeEmptyObjects)
      .filter((item) => item !== null && item !== undefined);
  } else if (obj !== null && typeof obj === "object") {
    const cleanedObj = Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = removeEmptyObjects(value);

      if (
        cleanedValue !== null &&
        cleanedValue !== undefined &&
        (typeof cleanedValue !== "object" ||
          Object.keys(cleanedValue).length > 0)
      ) {
        acc[key] = cleanedValue;
      }

      return acc;
    }, {} as { [key: string]: any });

    return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
  }

  return obj;
};

export default removeEmptyObjects;
