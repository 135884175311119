import type { APIV2 } from '../../../../common';

import { request } from '../request';
import CustomerAPI from './customerApi.abstract';

export default class CustomerAPIV2 extends CustomerAPI {

    getMetricsUsersMessagesCount = (
        token: string,
        from: number,
        till?: number
    ) => {
        return request<APIV2.Routes.Metrics.Users.Messages.GetCountResponse>(
            'get',
            `${this.getAPIBaseUrl()}/metrics/users/messages/count`,
            { from, till },
            this.getRequestParams(token),
        );
    }

    getVersion(): number {
        return 2;
    }
}
