import type { Metrics } from '../../../../../common';

import { useEffect, useState } from 'react';

import { apiGetChannelsCountByCustomer } from '@/api/metrics/channels.api';
import { useLocale } from '@/locales';

export function ZeroChannelsCustomers() {

    const { formatMessage } = useLocale();
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState<Metrics.Channels.ByCustomerCount>([]);

    useEffect(() => {
        setLoading(true);
        apiGetChannelsCountByCustomer({countLt: 1})
            .then(({data, status}) => {
                if (data) {
                    setMetrics(data);
                } else {
                    console.error('Failed to get zero channels customers metrics. Status: ', status);
                }
            })
            .catch((error) => {
                console.error('Failed to get zero channels customers metrics. Error: ', error);
            })
            .finally(() => setLoading(false));

    }, []);

    if (loading || !metrics.length) {
        // Not sure if we need to show preloader for this compopnent,
        // becase if there no zero channels customers anymore, we don't need to show this component at all,
        // but if show preloader before we know there no zero channels customers, and then not show component,
        // it will look like a bug.
        return null;
    }

    return (
        <div className="metric-widget">
            <h4>{formatMessage({ id: 'metrics.channels.zeroChannelsCustomers.title' })}</h4>
            <div>
                { metrics.map(({_id}) => _id).join(', ') }
            </div>
        </div>
    );
}
