import type { IUser, UserSession } from '../../../common/users/interfaces';
import type { UserState } from '@/interface/user/user';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: UserState = {
    noticeCount: 0,
    menuList: [],
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserSession(state, action: PayloadAction<{user: IUser, session: UserSession<true>}>) {
            Object.assign(state, action.payload);
        },
        unsetUserSession(state) {
            state.user = undefined;
            state.session = undefined;
        },
    },
});

export const { setUserSession, unsetUserSession } = userSlice.actions;

export default userSlice.reducer;
