import type { Customer } from '../../../../common';
import type { CustomerSearchSelectProps } from '../customer/CustomerSearchSelect';
import type { WrapperProps } from './components/Wrapper';

import { useDispatch, useSelector } from 'react-redux';

import { setCustomer } from '@/stores/filters.store';

import CustomerSearchSelect from '../customer/CustomerSearchSelect';
import Wrapper from './components/Wrapper';

export type WithCustomerSelectProps = WrapperProps 
  & Omit<CustomerSearchSelectProps, 'initialId' | 'onCustomerChange' | 'allowClear'>
  & {
    /**
     * Callback to be called when a customer is selected
     */
    onCustomerChange?: (customer: Customer.Info) => void;
  }

/**
 * Wrapper component that provides a customer select dropdown with search functionality.
 * Selected customer ID is stored in the redux store and can be accessed from it, 
 * but a callback can be provided to get the selected customer object.
 *
 * */
const WithCustomerSelect: React.FC<WithCustomerSelectProps> = ({
  children,
  position,
  order,
  onCustomerChange,
  selectStyle,
  ...selectProps
}) => {

  const dispatch = useDispatch();
  const { customerId } = useSelector((state) => state.filters);

  return (
    <>
      <Wrapper {...{position, order}}>
        <CustomerSearchSelect<false>
          {...selectProps}
          selectStyle={{minWidth: '100pt', ...selectStyle}}
          initialId={customerId}
          onCustomerChange={(customer) => {
            dispatch(setCustomer(customer._id));

            if (onCustomerChange) {
              onCustomerChange(customer);
            }
          }}
        />
      </Wrapper>
      {children}
    </>
  );
};

export default WithCustomerSelect;
