import type { Customer, Instance } from '../../../common';

/**
 * Based on customers info, and instance matched from instances list,
 * return the base URL for the customer server.
 * 
 * **WARNING:** Requires `instances` array to contain all instances. If instance not found - error will be thrown
 * 
 * @throws Error if customer matching instance not found
 * 
 * @param customer 
 * @param instances Available instances
 * @returns Customer server base URL
 */
export function getCustomerBaseUrl(
    customer: Customer.Info,
    instances: Instance.Info[],
) {
    const instance = instances.find((i) => i._id === customer.instance);

    if (!instance) {
        throw new Error(`Instance ${customer.instance} not found`);
    }

    return `https://${customer.hosts[0]}${instance.api.customerRoutesPrefix}`;
}
