export const enUS_whatsapp = {
  "whatsapp.title": `WhatsApp`,
  // That what directly under "whatsapp." namespace is common WhatsApp vocabulary
  "whatsapp.businessInformation": "Business Information",
  "whatsapp.businessName": "Business Name",
  "whatsapp.businessEmail": "Business Email",
  "whatsapp.businessWebsite": "Business Website",
  "whatsapp.businessAddress1": "Street Address 1",
  "whatsapp.businessAddress2": "Street Address 2",
  "whatsapp.businessCity": "City",
  "whatsapp.businessState": "State",
  "whatsapp.businessZipPostal": "Zip/Postal Code",
  "whatsapp.businessCountry": "Country",
  "whatsapp.businessPhoneCode": "Phone Code",
  "whatsapp.businessPhoneNumber": "Phone Number",
  "whatsapp.businessTimezone": "Timezone",
  "whatsapp.phone": "Phone",
  "whatsapp.phone.displayName": "Display Name",
  "whatsapp.phone.category": "Category",
  "whatsapp.phone.description": "Description",
  "whatsapp.registerPhoneNumber.title": `Register Phone Number`,
  "whatsapp.registerPhoneNumber.register.success": `Phone number registered successfully`,
  "whatsapp.whatsAppBusinessAccount": "WhatsApp Business Account",
  "whatsapp.whatsAppBusinessAccountId": "WhatsApp Business Account ID",
  "whatsapp.embeddedSignup.title": "Embedded Signup",
  "whatsapp.embeddedSignup.invitations.create.title": "Create Embedded Signup Invitation",
  "whatsapp.embeddedSignup.invitations.code": "Code",
  "whatsapp.embeddedSignup.invitations.codeExtra": "Leave empty if you want to auto-generate a new one",
  "whatsapp.embeddedSignup.invitations.codeValidation": "Code must be between 10 and 100 characters long and can only contain letters, numbers, underscores, and hyphens",
  "whatsapp.embeddedSignup.invitations.businessId": "Business ID",
  "whatsapp.embeddedSignup.invitations.businessIdExtra": "ID of an existing business, leave empty if are creating a new one",
  "whatsapp.embeddedSignup.invitations.expiresExtra": "If not provided, invitation will never expire.",
  "whatsapp.embeddedSignup.invitations.generateLink": "Generate Invite Link",
  "whatsapp.embeddedSignup.invitations.startAgain": "Start Again",
  "whatsapp.embeddedSignup.invitations.signupLink": "Signup URL:",
  "whatsapp.embeddedSignup.invitations.preVerifiedPhone": "Pre-verified Phone",
  "whatsapp.embeddedSignup.invitations.preVerifiedPhoneExtra": "List of pre-verified phone numbers, separated by commas",
};
