import type { Routes } from '../../../../common';
import type { FC } from 'react';

import './index.less';

import { Card } from 'antd';
import { useEffect, useState } from 'react';

import { apiGetInstances } from '@/api/instances.api';
import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter } from '@/locales';

import { Permission } from '../../../../common/permissions';
import AddInstanceModal from './AddInstanceModal';

const InstancesPage: FC = () => {

    const [loading, setLoading] = useState(true);
    const [instances, setInstances] = useState<Routes.Instances.ListResponse>([]);
    const [addInstanceModalVisible, setAddInstanceModalVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiGetInstances()
            .then(({data}) => {
                if (data) {
                    setInstances(data);
                }
            })
            .catch((error) => {
                console.error('Error loading instances', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="instances.title" />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.manageInstances]}>
                        <MyButton type="primary" onClick={() => setAddInstanceModalVisible(true)}>
                            <LocaleFormatter id="instances.addInstance" />
                        </MyButton>
                    </PermissionGate>
                </div>
            </div>
            <div className="content">
                {loading && <p>Loading...</p>}
                {instances.map((instance) => (
                    <Card key={instance._id} title={instance._id} className="card" bordered={false} hoverable>
                        <p>
                            Customers count: {instance.customersCount}
                        </p>
                        <p>
                            URL: <a href={instance.url}>{instance.url}</a>
                        </p>
                    </Card>
                ))}
            </div>

            <AddInstanceModal visible={addInstanceModalVisible} onCancel={() => setAddInstanceModalVisible(false)} />
        </div>
    );
};

export default InstancesPage;
