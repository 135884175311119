import type { Permissions, WAESAPIV1 } from "../../../../../../../../common";

import { Alert, message, Space, Spin, Tooltip } from "antd";
import { type FC, useEffect, useMemo, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";

import WaesAPIV1 from "@/api/services/waesApiV1";
import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import { useServiceToken } from "@/hooks/services/useServiceToken";
import { LocaleFormatter, useLocale } from "@/locales";

import { Services } from "../../../../../../../../common";
import InvitationForm from "./InvitationForm";

type InvitationCreateProps = {
  /**
   * Current user permissions
   */
  userPermissions: Permissions.Permission[];
  /**
   * Customer ID
   */
  customerId: string;
  /**
   * Embedded signup base URL
   */
  embeddedSignupBaseUrl?: string;
};

const InvitationCreate: FC<InvitationCreateProps> = ({
  userPermissions,
  customerId,
  embeddedSignupBaseUrl = "",
}) => {
  const tokenParams = useMemo(() => ({}), []);
  const { token: currentToken } = useServiceToken(
    userPermissions,
    Services.Name.waesApiV1,
    tokenParams
  );
  const { formatMessage } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [invitationUrl, setInvitationUrl] = useState<string | null>(null);

  const validToken = currentToken;

  useEffect(() => {
    // reset on customer change
    setHasError(false);
    setLoading(false);
  }, [customerId]);

  const onCopyInvitationLink = () => {
    if (invitationUrl) {
      navigator.clipboard.writeText(invitationUrl);
      message.success(
        formatMessage({ id: "global.tips.copyToClipboardSuccess" })
      );
    }
  };

  const createInvitation =
    (token: string) =>
    (formData: Omit<WAESAPIV1.Invitations.CreateRequest, "customerId">) => {
      setLoading(true);
      setHasError(false);

      new WaesAPIV1(embeddedSignupBaseUrl)
        .createInvitation(token, {
          customerId,
          ...formData,
        })
        .then(({ data }) => {
          if (data && data.invitationCode) {
            const url = `${embeddedSignupBaseUrl}/signup/${data.invitationCode}`;

            setInvitationUrl(url);
          } else {
            setHasError(true);
          }
        })
        .catch((error) => {
          console.error("Error sending whatsapp signup invitation", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

  if (loading || !validToken) {
    return <Spin spinning className="app-loading-wrapper transparent" />;
  }

  if (hasError) {
    return (
      <div>
        <LocaleFormatter id="global.errors.dataLoading" />
      </div>
    );
  }

  return (
    <div className="content">
      {invitationUrl ? (
        <div>
          <Alert
            message={<LocaleFormatter id="global.success" />}
            description={
              <div className="add-token-container">
                <div>
                  <LocaleFormatter id="whatsapp.embeddedSignup.invitations.signupLink" />
                </div>

                <Space.Compact>
                  <MyInput value={invitationUrl} readOnly />
                  <Tooltip
                    title={<LocaleFormatter id="global.tips.copyToClipboard" />}
                    placement="top"
                  >
                    <MyButton
                      type="default"
                      icon={<AiOutlineCopy />}
                      onClick={onCopyInvitationLink}
                    />
                  </Tooltip>
                </Space.Compact>
              </div>
            }
            style={{ margin: "24px 0" }}
            type="success"
            showIcon
          />
          <MyButton
            type="primary"
            onClick={() => {
              setInvitationUrl(null);
            }}
          >
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.startAgain" />
          </MyButton>
        </div>
      ) : (
        <InvitationForm
          onSubmit={createInvitation(currentToken)}
        />
      )}
    </div>
  );
};

export default InvitationCreate;
