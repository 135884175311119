import type { ServerEvents } from '../emitter';

import store from '@/stores';
import { addNotification } from '@/stores/notification.store';

import { Events } from '../../../../common/events';

export function attach(events: ServerEvents){
    events.addListener(Events.Name.notificationCreated, onCreated);
}

export function detach(events: ServerEvents){
    events.removeListener(Events.Name.notificationCreated, onCreated);
}

function onCreated(data: Events.NotificationCreatedData){
    store.dispatch(addNotification(data));
}
