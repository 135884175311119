import React from 'react';

import MyCheckBox from '@/components/basic/checkbox';
import MySelect from '@/components/basic/select';
import MyForm from '@/components/core/form';

import { Facebook } from '../../../../../../../common/channels/facebook';

interface Props {
	subscriptionObject: Facebook.Webhooks.SubscriptionObject | null;
	setSubscriptionObject: React.Dispatch<
		React.SetStateAction<Facebook.Webhooks.SubscriptionObject | null>
	>;
	subscriptionFields: string[];
	setSubscriptionFields: React.Dispatch<React.SetStateAction<any>>;
	required?: boolean;
}

const SubscribeForm: React.FC<Props> = ({
	subscriptionObject,
	setSubscriptionObject,
	subscriptionFields,
	setSubscriptionFields,
	required = true,
}) => {
	return (
		<>
			<MyForm.Item
				name="Subscription Type"
				label="Subscription Type"
				rules={[{ required }]}
				hidden={!required}
			>
				<MySelect
					value={subscriptionObject}
					onChange={setSubscriptionObject}
					dropdownStyle={{ zIndex: 1210 }}
					options={Object.values(
						Facebook.Webhooks.SubscriptionObject
					).map((value) => ({
						label: value,
						value,
					}))}
				/>
			</MyForm.Item>
			{!!subscriptionObject && (
				<MyForm.Item
					name="Subscription Fields"
					label="Subscription Fields"
					rules={[{ required }]}
					hidden={!required}
				>
					<div className="fields-checkbox-wrapper">
						{Facebook.Webhooks.SubscriptionObjectFields[subscriptionObject].map((field) => (
							<MyCheckBox
								onChange={(e) => {
									if (e.target.checked) {
										setSubscriptionFields([
											...subscriptionFields,
											field,
										]);
									} else {
										setSubscriptionFields(
											subscriptionFields.filter(
												(f: any) => f !== field
											)
										);
									}
								}}
								key={field}
							>
								{field}
							</MyCheckBox>
						))}
					</div>
				</MyForm.Item>
			)}
		</>
	);
};

export default SubscribeForm;
