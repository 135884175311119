import type { FC } from 'react';

import './index.less';

import { Col, Divider, Row } from 'antd';
import { AiFillExclamationCircle } from 'react-icons/ai';

import { AllChannelsCount } from '@/components/metrics/channels/AllChannelsCount';
import { ZeroChannelsCustomers } from '@/components/metrics/channels/ZeroChannelsCustomers';
import { useLocale } from '@/locales';



const DashBoardPage: FC = () => {

    const { formatMessage } = useLocale();

    return (
        <div>
            {/* Do we really need this title? Uses to much screen area */}
            {/* <h1 className='title'>Dashboard</h1> */}
            <Row gutter={16}>
                <Col span={8} >
                    <RequiresAttention />
                </Col>
                <Col span={8} >
                    <Divider orientation="left">{ formatMessage({ id: 'dashboard.generalMetrics' }) }</Divider>
                    {/* <h3>{ formatMessage({ id: 'dashboard.generalMetrics' }) }</h3> */}
                    <AllChannelsCount />
                </Col>
            </Row>
        </div>
    );
};

function RequiresAttention(){
    const { formatMessage } = useLocale();

    return (
        <div className="requires-attention">
            <Divider 
                orientation="left" 
                style={{borderColor: '#faad14'}}
            >
                <AiFillExclamationCircle style={{color: '#faad14', marginRight: '0.2em', verticalAlign: 'text-top'}} />
                { formatMessage({ id: 'dashboard.requiresAttention.alert' })}
            </Divider>
            <ZeroChannelsCustomers />
        </div>
    );
}

export default DashBoardPage;
