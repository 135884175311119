import { Customer } from './customer';

export namespace Instance {
    
    export interface Info {
        _id: string;
        url: string;
        api: APIInfo;
        customers?: Omit<Customer.Info, 'instance'>[];
        /**
         * Usage restrictions for the instance
         */
        restrictions: Restrictions.Settings;
    }

    export type APIInfo = {
        instanceApiVersions: {
            min: number;
            max: number;
        };
        customerApiVersions: {
            min: number;
            max: number;
        };
        customerRoutesPrefix: string;
    }

    export namespace Restrictions {
        /**
         * Check if the instance can accept a new customer
         * 
         * @param instance Target instance information
         * @param currentCustomersCount Current number of customers on the target instance
         * @param customer 
         *  (Optional) Customer to check if can be added to the instance.
         *  If not provided, only the instance restrictions that are 
         *  not related to particular customer are checked.
         * @returns 
         */
        export function canAcceptCustomer(
            instance: Omit<Info, 'customers'>,
            currentCustomersCount: number,
            // Reserved for future rules
            customer?: Customer.Info
        ): { allowed: true } | { allowed: false; reason: Reason } {
            if (instance.restrictions.maxCustomers && currentCustomersCount >= instance.restrictions.maxCustomers) {
                return {
                    allowed: false,
                    reason: Reason.maxCustomersReached,
                }
            }

            return { allowed: true };
        }

        export enum Reason {
            maxCustomersReached = 'maxCustomersReached',
        }

        export type Settings = {
            /**
             * Maximal number of customers allowed for the instance
             */
            maxCustomers?: number;
        }

    }
}
