import type { Dispatch } from "redux";

import { apiGetConfig } from "@/api/global.api";

import { setConfig } from "./global.store";
import { loadInstancesAsync } from "./instances.action";
import { loadSinglePartnerAsync } from "./partners.action";
import { setUI } from "./ui.store";
import { createAsyncAction } from "./utils";

export enum Module {
    'config' = 'config',
    'instances' = 'instances',
}

/**
 * Loading all required for application to function initial data.
 * 
 * @param modules - List of modules to load.
 *
 * */
export const loadAppAsync = createAsyncAction<Module[]>((modules) => {
    return async (dispatch, getState) => {

        const { user } = getState().user;

        if (!user) {
            console.warn('User not loaded, skipping loading app data');

            return;
        }

        dispatch(setUI({ appLoading: true }));

        // Fetch user's partner profile
        if (user?.partnerId) {
            await dispatch(loadSinglePartnerAsync(user.partnerId));
        }

        return Promise.allSettled(
            modules.map((module) => {
                if (module === Module.config) {
                    dispatch(loadConfigAsync())
                }

                if (module === Module.instances) {
                    return dispatch(loadInstancesAsync());
                }

                return Promise.resolve();
            })
        )
        .then((results) => {
            for (const result of results) {
                if (result.status === 'rejected') {
                    console.error('Error loading module', result.reason);
                }
            }

            return dispatch(setUI({ appLoading: false }));
        });
    };
});

export const loadConfigAsync = createAsyncAction<void>(() => {
    return async (dispatch: Dispatch) => {
      const { data } = await apiGetConfig();


      if (data) {
        dispatch(setConfig(data));
      } else {
        console.error("Failed to load config");
      }
    };
  });