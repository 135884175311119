import type { Customer, Partners } from '../../../../common';
import type { SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import type { CSSProperties} from 'react';

import { debounce, pick } from 'lodash';
import { useEffect, useState } from 'react';

import { apiGetCustomers } from '@/api/customers.api';
import MySelect from '@/components/basic/select';
import { useLocale } from '@/locales';


export type CustomerSearchSelectProps<
    AllowClear extends boolean = boolean,
> = {
    /**
     * Callback to be called when a customer is selected
     */
    onCustomerChange: AllowClear extends true ? (customer: Customer.Info | undefined) => void : (customer: Customer.Info) => void;
    /**
     * The initial customer ID to select. 
     * After customer info is fetched, `onCustomerChange` will be called.
     */
    initialId?: string;
    /**
     * If `true` and `initialId` is not provided, will load any first customer info on mount.
     * Default is `true`.
     */
    initialLoadAny?: boolean;
    /**
     * Show the instance of the customer in the dropdown. Default is `true`.
     */
    showInstance?: boolean;
    /**
     * Only customers from this instance will be shown.
     */
    instanceId?: string;
    /**
     * Only customers belongs to this partner will be shown.
     */
    partnerId?: string;
    /**
     * Additionaly filter the customers after they are fetched.
     * This function should return `true` if the customer should be included.
     * If all customers in batch filetred out, component will try to load next batch, 
     * and so on until at least one customer passes filtering function, or all customers are fetched.
     * If need to filter by instance and/or partner, use `instanceId` or `partnerId` props instead.
     */
    postFilter?: (customer: Customer.Info) => boolean;
    selectStyle?: CSSProperties;
    allowClear?: AllowClear;
} & Pick<SelectProps<string, DefaultOptionType>, 'size' | 'disabled' | 'direction' | 'placeholder'>;

/**
 * Component that provides a customer select dropdown with search functionality
 *
 * */
function CustomerSearchSelect<AllowClear extends boolean = boolean>({
    onCustomerChange,
    initialId,
    initialLoadAny = true,
    showInstance = true,
    postFilter,
    instanceId,
    partnerId,
    placeholder,
    ...props
}: CustomerSearchSelectProps<AllowClear>){
    const { formatMessage } = useLocale();
    const [customerId, setCustomerId] = useState<string>(initialId ?? '');
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<Customer.Info[]>([]);

    // It is here but for some reason placeholder don't shown now
    placeholder ??= formatMessage({id: 'customers.select.placeholder'});

    useEffect(() => {
        if ((initialId || initialLoadAny) && !customers.length) {
            setLoading(true);
            apiGetCustomers({ search: initialId, limit: 1, instanceId, partnerId })
                .then(({data}) => {
                    const customers = postFilter ? data.customers.filter(postFilter) : data.customers;

                    setCustomers(customers);

                    if (customers.length) {
                        if (!customerId) {
                            setCustomerId(customers[0]._id);
                        }

                        onCustomerChange(customers[0]);
                    } else if(!data.customers.length && initialId) {
                        console.debug(`Customer with ID ${initialId} not found`);
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Failed to search customers', err);
                    setLoading(false);
                });
        }
    }, [initialId]);

    const onSelected = (id?: string) => {

        if (!id) {
            // Selected value could be undefined if `allowClear` is `true`
            setCustomerId('');
            // In 99% cases such type casting is a bad thing,
            // but here otherwise would need to write monstrous type parametrs construction
            onCustomerChange(undefined as any);

            return;
        }

        const customer = customers.find((c) => c._id === id);

        if (customer) {
            setCustomerId(id);
            onCustomerChange(customer);
        } else {
            // Probably should never happen unless some race condition in select component
            console.error(`ID ${id} not found in customers list`);
        }
    }

    // This made as separate function to be able to call it recursively
    const loadCustomers = (
        search: string,
        onComplete: (customers: (Customer.Info & {partner?: Partners.Partner})[]) => void, 
        skip = 0
    ) => {
        const step = 50;

        apiGetCustomers({ search, skip, limit: step, instanceId, partnerId })
            .then(({data}) => {
                const customers = postFilter ? data.customers.filter(postFilter) : data.customers;

                if (customers.length) {
                    onComplete(customers);
                    // Previous batch was all filtered out by postFilter
                    // but `total` shows there are more customers,
                    // so load next batch
                } else if (data.customers.length && data.total > skip + step) {
                    loadCustomers(search, onComplete, skip + step);
                } else {
                    onComplete([]);
                }
            })
            .catch((err) => {
                console.error('Failed to search customers', err);
                onComplete([]);
            });
    }

    const searchCustomers = debounce((search: string) => {
        setLoading(true);
        loadCustomers(
            search,
            (customers) => {
                setCustomers(customers);
                setLoading(false);
            }
        );
    }, 500);

    const onSearch = (search: string) => {
        if (search.length < 2) {
            console.debug('Search term too short');

            return;
        }

        searchCustomers(search);
    }

    return (
        <MySelect
            value={ customerId }
            onChange={ onSelected }
            onSearch={ onSearch }
            options={customers.map((c) => ({
                label: showInstance ? `${c._id} (${c.instance})` : c._id,
                value: c._id,
            }))}
            showSearch
            loading={ loading }
            style={props.selectStyle}
            placeholder={placeholder}
            {...pick(props, 'direction', 'size', 'disabled', 'allowClear')}
        />
    );
}

export default CustomerSearchSelect;
