import './UserLatestGrantsCompact.less';

import { Button, Dropdown, Space } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';

import { apiGetAccessGrants, apiGetAuthenticationUrl } from '@/api/accessGrants.api';
import { events } from '@/events';
import { LocaleFormatter } from '@/locales';

import { Customer, Events } from '../../../../../common';
import { AccessGrants } from '../../../../../common/routes';

type UserLatestGrantsCompactProps = {
    uid: string;
    /**
     * The number of items to load and display. Defaults to 5.
     */
    limit?: number;
}

export function UserLatestGrantsCompact({
    uid,
    limit = 5,
}: UserLatestGrantsCompactProps) {

    const [loading, setLoading] = useState(false);
    const [loadLoginBy, setLoadLoginBy] = useState<null | string>(null);
    const [list, setList] = useState<Customer.AccessGrant<true, string>[]>([]);

    const load = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        apiGetAccessGrants({
            uid,
            status: Customer.AccessGrantStatus.approved,
            sortField: AccessGrants.ListSortField.created,
            sortDirection: 'desc',
            limit,
        })
        .then(({ data, status }) => {
            setList(data?.data ?? []);
            console.log('User latest access grants', data?.data);

            if (!data?.data) {
                console.error('Failed to load user latest access grants', { status });
            }
        })
        .catch((error) => {
            console.error('Failed to load user latest access grants', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loginBy = (grantId: string) => {
        setLoadLoginBy(grantId);
        apiGetAuthenticationUrl(grantId)
            .then(({ data, status }) => {
                if (data) {
                    window.open(data.url, '_blank', 'noopener noreferrer');
                } else {
                    console.error('Failed to get authentication URL', { status });
                }
            })
            .catch((error) => {
                console.error('Failed to get authentication URL', error);
            })
            .finally(() => {
                setLoadLoginBy(null);
            });
    }

    const onUpdatedOrCreatedEvent = (grant: Customer.AccessGrant<true, string>) => {
        if (grant.uid !== uid) {
            return;
        }

        if (grant.status === Customer.AccessGrantStatus.approved) {
            setList((list) => {
                return [grant, ...list.filter((g) => g._id !== grant._id)]
                    .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                    .slice(0, limit);
            });
        } else if (grant.status === Customer.AccessGrantStatus.revoked) {
            setList((list) => list.filter((g) => g._id !== grant._id));
        }
    }

    const onDeletedEvent = ({id}: {id: string}) => {
        setList(list.filter((g) => g._id !== id));
    }

    const deleteExpired = () => {
        setList((list) => list.filter((g) => {
            if (!g.expires) {
                return true;
            }

            const expires = new Date(g.expires).getTime();

            return expires > Date.now();
        }));
    }

    useEffect(() => {
        load();
        events.addListener(Events.Name.accessGrantCreated, onUpdatedOrCreatedEvent);
        events.addListener(Events.Name.accessGrantUpdated, onUpdatedOrCreatedEvent);
        events.addListener(Events.Name.accessGrantDeleted, onDeletedEvent);
        const expiredInterval = setInterval(deleteExpired, 1000 * 60 * 5);

        return () => {
            events.removeListener(Events.Name.accessGrantCreated, onUpdatedOrCreatedEvent);
            events.removeListener(Events.Name.accessGrantUpdated, onUpdatedOrCreatedEvent);
            events.removeListener(Events.Name.accessGrantDeleted, onDeletedEvent);
            clearInterval(expiredInterval);
        };
    }, []);

    const renderLoginText = (grant: Customer.AccessGrant<true, string>) => {
        return (
            <LocaleFormatter 
                id="customers.access.loginIntoAsRole" 
                values={{ customerId: grant.customerId, role: grant.customerRoles?.join(', ') }}
            />
        );
    }

    const renderLatestButton = () => {
        return (
            <Button 
                size="small" 
                type="primary"
                loading={loadLoginBy === list[0]._id}
                onClick={() => loginBy(list[0]._id)}
                disabled={loadLoginBy !== null}
            >
                <Space>
                    { renderLoginText(list[0]) }
                    { list.length > 1 &&
                    <span className="multiple-count-wrap">
                        <span className="count">+{list.length - 1}</span>
                        <AiOutlineDown />
                    </span>
                    }
                </Space>
            </Button>
        )
    }
    
    if (!list.length) {
        return null;
    } else if (list.length === 1) {
        return (
            <div className="user-latest-grants-compact single">
                { renderLatestButton() }
            </div>
        );
    }

    return (
        <div className="user-latest-grants-compact multiple">
            <Dropdown 
                menu={{
                    items: list.slice(1).map((grant) => ({
                        key: grant._id,
                        label: renderLoginText(grant),
                        onClick: () => loginBy(grant._id),
                        loading: loadLoginBy === grant._id,
                        disabled: loadLoginBy !== null,
                    })),
                }}
            >
                { renderLatestButton() }
            </Dropdown>
        </div>
    );
}
