import type { WAESAPIV1 } from "../../../../common";

import { request } from "../request";
import WaesAPI from "./waesApi.abstract";

export default class WaesAPIV1 extends WaesAPI {
  createInvitation = (
    token: string,
    params: WAESAPIV1.Invitations.CreateRequest
  ) => {
    return request<WAESAPIV1.Invitations.CreateResponse>(
      "post",
      `${this.getAPIBaseUrl()}/invitations`,
      params,
      this.getRequestParams(token)
    );
  };

  getVersion(): number {
    return 1;
  }
}
