import type { Permission } from '../../../common/permissions';
import type { IUser } from '../../../common/users/interfaces';
import type { FC } from 'react';
import type { RouteProps } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { hasPermissions } from '@/stores/user.action';

import { Services } from '../../../common';
import NotAuthorized from './NotAuthorized';

export type PrivateRouteAuth<S extends Services.Name = Services.Name> = boolean | {
    /**
     * Permissions required to access this route
     */
    permissions?: Permission[];
    /**
     * Permissions required to access this route for specific service
     */
    servicePermissions?: {
        service: S;
        permissions: Services.Permissions.ServicePermissionsType[S][];
    };
    /**
     * Extra condition to access this route
     */
    condition?: (user: IUser) => boolean;
};

export type PrivateRouteProps = RouteProps & {
    auth: PrivateRouteAuth;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ auth, element }) => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    let allow = false;
    
    if (typeof auth === 'boolean') {
        allow = auth;
    } else {
        let allowPermissions = false;
        let allowService = false;
        let allowCondition = false;

        if (user) {
            if (auth.permissions) {
                allowPermissions = Boolean(dispatch(hasPermissions(auth.permissions)));
            } else {
                allowPermissions = true;
            }

            if (auth.servicePermissions) {
                const { service, permissions } = auth.servicePermissions;

                allowService = Services.Permissions.hasServicePermissions<Services.Permissions.ServicePermissionsType[typeof service]>(
                    Services.Permissions.map[service],
                    permissions,
                    user.permissions
                );
            } else {
                allowService = true;
            }
    
            if (auth.condition) {
                allowCondition = auth.condition(user);
            } else {
                allowCondition = true;
            }

            allow = allowPermissions && allowService && allowCondition;
        }

    }

    return allow ? (element as React.ReactElement) : <NotAuthorized />;
};

export default PrivateRoute;
