import type { DateRange } from '@/interface/dateRange';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState: FiltersState = {
    dateRange: {
        from: dayjs().add(-7, 'd').unix() * 1000,
        till: dayjs().unix() * 1000,
    },
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setCustomer(state, action: PayloadAction<string>) {
            return {
                ...state,
                customerId: action.payload,
            }
        },
        setDateRange(state, action: PayloadAction<DateRange>) {
            return {
                ...state,
                dateRange: action.payload,
            }
        }
    },
});

export const { setCustomer, setDateRange } = filtersSlice.actions;

export default filtersSlice.reducer;

export type FiltersState = {
    customerId?: string;
    dateRange: DateRange;
}
