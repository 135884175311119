import { WebsiteChat } from "./website_chat";
import { Notifications } from "./notifications";
import { Pipelines } from "./pipelines";
import { Customer } from "./customer";

export namespace Events {

    export const PING_MSG = 'ping';
    
    export enum Name {
        notificationCreated = 'notificationCreated',
        pipelineCreated = 'pipelineCreated',
        pipelineUpdated = 'pipelineUpdated',
        websiteChatStateUpdated = 'websiteChatStateUpdated',
        accessGrantCreated = 'accessGrantCreated',
        accessGrantUpdated = 'accessGrantUpdated',
        accessGrantDeleted = 'accessGrantDeleted',
    }
    
    export type NotificationCreatedData<IsJSON extends boolean = true, IDType extends any = string> = Notifications.Notification<IsJSON> & {_id: IDType};
    
    export type PipelineCreatedData<IsJSON extends boolean = true, IDType extends any = string> = Pipelines.Run<IsJSON> & {_id: IDType};
    
    export type PipelineUpdatedData<IsJSON extends boolean = true, IDType extends any = string> = PipelineCreatedData<IsJSON, IDType>;
    
    export type EventNameDataMap<IsJSON extends boolean = true> = {
        [Name.notificationCreated]: NotificationCreatedData<IsJSON>;
        [Name.pipelineCreated]: PipelineCreatedData<IsJSON>;
        [Name.pipelineUpdated]: PipelineUpdatedData<IsJSON>;
        [Name.websiteChatStateUpdated]: WebsiteChat.State;
        [Name.accessGrantCreated]: Customer.AccessGrant<IsJSON, IsJSON extends false ? HasToJSONString : string>;
        [Name.accessGrantUpdated]: Customer.AccessGrant<IsJSON, IsJSON extends false ? HasToJSONString : string>;
        [Name.accessGrantDeleted]: {
            id: IsJSON extends false ? HasToJSONString : string;
        };
    }

    // Added to not install mongodb types in common and fronted packages but still have some type guard
    type HasToJSONString = { toJSON(): string };
}
