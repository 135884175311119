import "./InvitationCreatePage.less";

import { type FC, useState } from "react";
import { useSelector } from "react-redux";

import WithCustomerSelect from "@/components/filters/WithCustomerSelect";
import { LocaleFormatter } from "@/locales";

import { type Customer } from "../../../../../../../common";
import InvitationCreate from "./components/InvitationCreate";

const InvitationCreatePage: FC = () => {
  const { config } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  const [customer, setCustomer] = useState<Customer.Info | null>(null);

  return (
    <WithCustomerSelect position="left" onCustomerChange={setCustomer}>
      <div className="container metrics-container">
        <div className="title">
          <h1>
            <span className="subtitle whatsapp-subtitle">Whatsapp</span>
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.create.title" />
          </h1>
        </div>
        <div className="content">
          {customer ? (
            <InvitationCreate
              userPermissions={user!.permissions}
              customerId={customer._id}
              embeddedSignupBaseUrl={config?.whatsappEmbeddedSignup?.baseUrl}
            />
          ) : (
            <div className="no-customer-description">
              <LocaleFormatter id="customers.select.noCustomerSelected" />
            </div>
          )}
        </div>
      </div>
    </WithCustomerSelect>
  );
};

export default InvitationCreatePage;
