export const enUS_websiteChat = {
  "websiteChat.title": "Website Chat",
  "websiteChat.deployments.title": "Deployments",
  "websiteChat.table.header.gcpName": "Google Cloud Project",
  "websiteChat.table.header.backendVersion": "Backend Version",
  "websiteChat.table.header.frontendVersion": "Frontend Version",
  "websiteChat.table.header.backendUrl": "Backend URL",
  "websiteChat.table.header.frontendUrl": "Frontend URL",
  "websiteChat.table.header.backendLocation": "Backend Location",
  "websiteChat.table.header.firestoreLocation": "Firestore Location",
  "websiteChat.table.header.backendWebhookRepeatInterval":
    "Webhook Repeat Interval",
  "websiteChat.table.header.backendWebhookRepeatIntervalOptional":
    "Webhook Repeat Interval (optional)",
  "websiteChat.table.header.backendWebhookRepeatCount": "Webhook Repeat Count",
  "websiteChat.table.header.backendWebhookRepeatCountOptional":
    "Webhook Repeat Count (optional)",
  "websiteChat.table.header.customer": "Customer",
  "websiteChat.table.header.customerOptional": "Customer (optional)",
  'websiteChat.table.header.channelAccountId': 'Channel Account ID',
  'websiteChat.table.header.channelAccountIdDescription':
      'Edit this field if the customer has more than one website chat connected. Use the value of the corresponding channel account if it was already added before. Use a different value if this is a new, different installation.',
  "websiteChat.button.deployFrontend": "Deploy Frontend",
  "websiteChat.button.bulkDeployFrontend": "Bulk Deploy Frontend",
  "websiteChat.button.deployFrontend.description":
    "Are you sure you want to deploy the frontend?",
  "websiteChat.button.deploySelectedFrontend":
    "For selected {{count}}: Deploy Frontend",
  "websiteChat.button.deployBackend": "Deploy Backend",
  "websiteChat.button.bulkDeployBackend": "Bulk Deploy Backend",
  "websiteChat.button.deploySelectedBackend":
    "For selected {{count}}: Deploy Backend",
  "websiteChat.button.newDeploy": "New Deployment",
  "websiteChat.deploy.title": "Deploy Website Chat",
  "websiteChat.button.bulkDeploy.enterBackendVersion":
    "Please enter backend version",
  "websiteChat.button.bulkDeploy.enterFrontendVersion":
    "Please enter frontend version",
    "websitechat.deployFrontend.success": "Frontend deployment has been initiated",
    "websitechat.deployBackend.success": "Backend deployment has been initiated",
};
