import type { Permissions, Services } from '../../../../common';
import type { Tokens } from '../../../../common/services';

import { useEffect, useState } from 'react';

import { serviceCreateTokenCached } from '@/services/tokens/serviceCreateTokenCached';
import { getJwtExpirationTimeLeft } from '@/utils/getJwtExpirationTimeLeft';


/**
 * Creates new or uses cached in memory token for same service and with same permissions created earlier,
 * and loads new one when token is about to expire.
 * 
 * **WARNING**: Nature of hooks and useEffect is such that returned token would be
 * updated not in the same render hook is called. 
 * Therefore additional measures should be taken to not run parent component requests
 * with previous token.
 * 
 * @param userPermissions - User permissions to use for token creation. If null, token will be null.
 * @see serviceCreateTokenCached for other arguments and description
 */
export function useServiceToken<
    S extends Services.Name, 
    P extends Services.Permissions.ServicePermissionsType[S] = Services.Permissions.ServicePermissionsType[S],
    CP extends Tokens.CreateParams[S] = Tokens.CreateParams[S]
>(
    // Here parameter allowed to be null as hack to allow calling hook before asserting user is logged in
    userPermissions: Permissions.Permission[] | null,
    service: S,
    tokenCreateParams: CP,
    permissions: P[] | 'available' | 'none' = 'available',
    ttl: number = 1000 * 60 * 30,
    refreshWhenLeftMS: number = 1000 * 60 * 5,
){

    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<{token: string, params: CP} | null>(null);

    const createToken = () => {
        if (userPermissions) {
            setLoading(true);
            setToken(null);
            const params = {...tokenCreateParams};

            serviceCreateTokenCached(userPermissions, service, tokenCreateParams, permissions, ttl, refreshWhenLeftMS)
                .then((token) => {
                    setToken({token, params});
                    setLoading(false);
                })
                .catch((e) => {
                    console.error('Failed to get token', e);
                    setLoading(false);
                });
        } else {
            setToken(null);
        }
    }

    useEffect(() => {
        createToken();
    }, [userPermissions, service, tokenCreateParams, permissions, ttl, refreshWhenLeftMS]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;

        if (token){
            const timeLeft = getJwtExpirationTimeLeft(token.token);

            if (timeLeft <= refreshWhenLeftMS){
                createToken();
            } else {
                timeout = setTimeout(createToken, timeLeft - refreshWhenLeftMS);
            }
        }

        return () => {
            if (timeout !== null){
                clearTimeout(timeout);
            }
        }
    }, [token]);

    return { 
        loading,
        // @ts-expect-error - This error has no sense, overwriting is conditional and this for default value
        params: tokenCreateParams,
        ...(token ? {...token} : {token: null}),
    };
}
