import type { Instance } from '../../../../common';

export default abstract class CustomerAPI {

    constructor(protected customerBaseUrl: string) {}

    static isVersionSupportedByInstance(version: number, instance: Instance.Info): boolean {
        return version >= instance.api.customerApiVersions.min && version <= instance.api.customerApiVersions.max;
    }

    getAPIBaseUrl(): string {
        return `${this.customerBaseUrl}/api/v${this.getVersion()}`;
    }

    protected getRequestParams = (token: string) => {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: false,
        };
    }

    abstract getVersion(): number;
}
