
export namespace Onboarding {

    export type NewByDate = {
        group: DateGroup;
    } & ({
        byPartner: false;
        result: DateCount[];
    } | {
        byPartner: true;
        result: DateByPartnerCount[];
    });

    export enum DateGroup {
        day = 'day',
        week = 'week',
        month = 'month',
        year = 'year',
    }

    export type DateCount = {
        date: string;
        count: number;
    }

    export type DateByPartnerCount = {
        date: string;
        partners: {
            /**
             * If null, the count is customers without a partner.
             */
            partnerId: string | null;
            count: number;
        }[];
    }
}
