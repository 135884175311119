import "./index.less";

import { type FC } from "react";

import { LocaleFormatter } from "@/locales";


const CustomerMetricsPage: FC = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <LocaleFormatter id="customerMetrics.title" />
        </h1>
        <div className="title-actions"></div>
      </div>
      <div className="content"></div>
    </div>
  );
};

export default CustomerMetricsPage;
