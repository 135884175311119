import type { FC } from 'react';
import type { RouteObject } from 'react-router';

import { lazy } from 'react';
import { Navigate } from 'react-router';
import { Outlet, useRoutes } from 'react-router-dom';

import UserTokensPage from '@/pages/channels/facebook/tokens';
import WebhookRouterPage from '@/pages/channels/facebook/webhooks-router';
import AddFacebookWHRouterAppPage from '@/pages/channels/facebook/webhooks-router/add';
import WebsiteChatPage from '@/pages/channels/website-chat';
import DeployWebsiteChat from '@/pages/channels/website-chat/deploy';
import WhatsappPage from '@/pages/channels/whatsapp';
import InvitationCreatePage from '@/pages/channels/whatsapp/embedded-signup/invitations/InvitationCreatePage';
import WhatsappRegisterPhoneNumberPage from '@/pages/channels/whatsapp/registerPhoneNumber';
import CustomerMetricsPage from '@/pages/customerMetrics';
import AgentMessagesCountPage from '@/pages/customerMetrics/pages/AgentMessagesCount/AgentMessagesCountPage';
import CustomersPage from '@/pages/customers';
import AccessCustomersPage from '@/pages/customers/access';
import AddCustomerPage from '@/pages/customers/add';
import ConfigCustomerPage from '@/pages/customers/config';
import MoveCustomerPage from '@/pages/customers/move';
import AccessRequestPage from '@/pages/customers/request';
import Dashboard from '@/pages/dashboard';
import InstancesPage from '@/pages/instances';
import LayoutPage from '@/pages/layout';
import LoginPage from '@/pages/login';
import PartnersPage from '@/pages/partners';
import AddPartnerPage from '@/pages/partners/add';
import EditPartnerPage from '@/pages/partners/edit';
import ViewPartnerPage from '@/pages/partners/view';
import UsersPage from '@/pages/users';
import AddUserPage from '@/pages/users/add';
import EditApiTokensPage from '@/pages/users/api-tokens';
import EditUserPage from '@/pages/users/edit';
import MePage from '@/pages/users/me';

import { APIV2, Services, WAES } from '../../../common';
import { Permission } from '../../../common/permissions';
import WrapperRouteComponent from './config';

const NotFound = lazy(() => import(/* webpackChunkName: "404'"*/ '@/pages/404'));

const routeList: RouteObject[] = [
    {
        path: '/login',
        element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />,
    },
    {
        path: '/',
        element: <WrapperRouteComponent element={<LayoutPage />} titleId="" auth />,
        children: [
            {
                path: '',
                element: <Navigate to="dashboard" />,
            },
            {
                path: 'dashboard',
                element: <WrapperRouteComponent element={<Dashboard />} titleId="title.dashboard" auth />,
            },
            {
                path: 'instances',
                element: <WrapperRouteComponent element={<InstancesPage />} titleId="title.instances" auth />,
            },
            {
                path: 'customers',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <WrapperRouteComponent element={<CustomersPage />} titleId="title.customers" auth />,
                    },
                    {
                        path: 'add',
                        element: (
                            <WrapperRouteComponent
                                element={<AddCustomerPage />}
                                titleId="title.customers.add"
                                auth={{
                                    permissions: [Permission.setupCustomer]
                                }}
                            />
                        ),
                    },
                    {
                        path: 'config/:instanceId/:customerId',
                        element: (
                            <WrapperRouteComponent
                                element={<ConfigCustomerPage />}
                                titleId="title.customers.config"
                                auth={{
                                    permissions: [Permission.customersConfigsSafeAccess]
                                }}
                            />
                        ),
                    },
                    {
                        path: 'move/:instanceId/:customerId',
                        element: (
                            <WrapperRouteComponent
                                element={<MoveCustomerPage />}
                                titleId="title.customers.move"
                                auth={{
                                    permissions: [Permission.customersMigrateInstances]
                                }}
                                
                            />
                        ),
                    },
                    {
                        path: 'access',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: (
                                    <WrapperRouteComponent
                                        element={<AccessCustomersPage />}
                                        titleId="title.customers.access"
                                        auth
                                    />
                                ),
                            },
                            {
                                path: 'request',
                                element: (
                                    <WrapperRouteComponent
                                        element={<AccessRequestPage />}
                                        titleId="customers.access.requestAccess"
                                        auth
                                    />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'customer-metrics',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent
                                element={<CustomerMetricsPage />}
                                titleId="title.customerMetrics"
                                auth={{
                                    permissions: [Permission.customersMetricsView],
                                }}
                            />
                        ),
                    },
                    {
                        path: 'agents-messages-count',
                        element: (
                            <WrapperRouteComponent
                                element={<AgentMessagesCountPage />}
                                titleId="title.customerMetrics.agentsMessagesCount"
                                auth={{
                                    permissions: [Permission.listCustomers],
                                    servicePermissions: {
                                        service: Services.Name.customerApiV2,
                                        permissions: [APIV2.Permission.metrics_view],
                                    },
                                }}
                            />
                        ),
                    },
                ]
            },
            {
                path: 'me',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent element={<MePage />} titleId="title.me" auth />
                        )
                    },
                    {
                        path: 'api-tokens',
                        element: (
                            <WrapperRouteComponent
                                element={<EditApiTokensPage myself />}
                                titleId="title.users.manageApiTokens"
                                auth={{
                                    permissions: [Permission.manageUsers]
                                }}
                            />
                        ),
                    }
                ],
            },
            {
                path: 'users',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent
                                element={<UsersPage />}
                                titleId="title.users"
                                auth={{
                                    permissions: [Permission.manageUsers]
                                }}
                            />
                        ),
                    },
                    {
                        path: 'add',
                        element: (
                            <WrapperRouteComponent
                                element={<AddUserPage />}
                                titleId="title.users.add"
                                auth={{
                                    permissions: [Permission.manageUsers]
                                }}
                            />
                        ),
                    },
                    {
                        path: ':id/edit',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: (
                                    <WrapperRouteComponent
                                        element={<EditUserPage />}
                                        titleId="title.users.edit"
                                        auth={{
                                            permissions: [Permission.manageUsers]

                                        }}
                                    />
                                )
                            },
                            {
                                path: 'api-tokens',
                                element: (
                                    <WrapperRouteComponent
                                        element={<EditApiTokensPage />}
                                        titleId="title.users.manageApiTokens"
                                        auth={{
                                            permissions: [Permission.manageUsers]
                                        }}
                                    />
                                ),
                            },
                                
                        ]
                    },
                ],
            },
            {
                path: 'partners',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent
                                element={<PartnersPage />}
                                titleId="title.partners"
                                auth={{
                                    permissions: [Permission.partnersList],
                                    condition: (user) => !user?.partnerId,
                                }}
                            />
                        ),
                    },
                    {
                        path: ':partnerId',
                        element: (
                            <WrapperRouteComponent
                                element={<ViewPartnerPage />}
                                titleId="title.partners.profile"
                                auth
                            />
                        ),
                    },
                    {
                        path: ':partnerId/edit',
                        element: (
                            <WrapperRouteComponent element={<EditPartnerPage />} titleId="title.partners.edit" auth />
                        ),
                    },
                    {
                        path: 'partner/add',
                        element: (
                            <WrapperRouteComponent element={<AddPartnerPage />} titleId="title.partners.add" auth />
                        ),
                    },
                ],
            },
            {
                path: 'channels',
                element: <Outlet />,
                children: [
                    {
                        path: 'facebook',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: <Navigate to="tokens" />,
                            },
                            {
                                path: 'tokens',
                                element: (
                                    <WrapperRouteComponent
                                        element={<UserTokensPage />}
                                        titleId="facebook.tokens.title"
                                        auth
                                    />
                                ),
                            },
                            {
                                path: 'webhooks',
                                element: (
                                    <WrapperRouteComponent
                                        element={<WebhookRouterPage />}
                                        titleId="facebook.webhooks.title"
                                        auth={{
                                            permissions: [Permission.facebookWebhooksRouterList]
                                        }}
                                    />
                                ),
                            },
                            {
                                path: 'webhooks/add',
                                element: (
                                    <WrapperRouteComponent
                                        element={<AddFacebookWHRouterAppPage />}
                                        titleId="facebook.webhooks.add"
                                        auth={{
                                            permissions: [Permission.facebookWebhooksRouterAdd],
                                        }}
                                    />
                                ),
                            }
                        ]
                    },
                    {
                        path: 'website-chat',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: <Navigate to="deployments" />,
                            },
                            {
                                path: 'deployments',
                                element: (
                                    <WrapperRouteComponent
                                        element={<WebsiteChatPage />}
                                        titleId="title.websiteChat"
                                        auth={{
                                            permissions: [Permission.listCustomers]
                                        }}
                                    />
                                )
                            },
                            {
                                path: 'deployments/deploy',
                                element: (
                                    <WrapperRouteComponent
                                        element={<DeployWebsiteChat />}
                                        titleId="title.deployWebsiteChat"
                                        auth={{
                                            permissions: [Permission.setupCustomer]
                                        }}
                                    />
                                )
                            }
                        ]
                    },
                    {
                        path: 'whatsapp',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: (
                                    <WrapperRouteComponent
                                        element={<WhatsappPage />}
                                        titleId="title.whatsapp"
                                        auth={{
                                            permissions: [Permission.whatsappUtils]
                                        }}
                                    />
                                ),
                            },
                            {
                                path: 'register-phone-number',
                                element: (
                                    <WrapperRouteComponent
                                        element={<WhatsappRegisterPhoneNumberPage />}
                                        titleId="whatsapp.registerPhoneNumber.title"
                                        auth={{
                                            permissions: [Permission.whatsappUtils]
                                        }}
                                    />
                                ),
                            },
                            {
                                path: 'embedded-signup',
                                element: (
                                    <WrapperRouteComponent
                                        element={<InvitationCreatePage />}
                                        auth={{
                                            permissions: [Permission.whatsappEmbeddedSignup],
                                            servicePermissions: {
                                                service: Services.Name.waesApiV1,
                                                permissions: [WAES.Permissions.Permission.invitations_create],
                                            }
                                        }}
                                        titleId="whatsapp.embeddedSignup.title"
                                    />
                                ),
                            },
                        ]
                    },
                ],
            },
            {
                path: '*',
                element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFound" />,
            },
        ],
    },
];

const RenderRouter: FC = () => {
    const element = useRoutes(routeList);

    return element;
};

export default RenderRouter;
