import type { Permissions, TexterCommon } from '../../../../../../../common';
import type { DateRange } from '@/interface/dateRange';

import { Spin } from 'antd';
import { type FC, useEffect, useMemo, useState } from 'react';

import CustomerAPIV2 from '@/api/services/customerApiV2';
import { Table } from '@/components/customerMetrics/agentMessagesCount/Table';
import { useServiceToken } from '@/hooks/services/useServiceToken';
import { LocaleFormatter } from '@/locales';

import { Services} from '../../../../../../../common';


type AgentMessagesCountProps = {
    /**
     * Current user permissions
     */
    userPermissions: Permissions.Permission[]; // Added as property to not depend on redux store in component just for this
    /**
     * Customer ID
     */
    customerId: string;
    /**
     * Customer server base URL
     */
    customerBaseUrl: string;
    dateRange: DateRange;
}

const AgentMessagesCount: FC<AgentMessagesCountProps> = ({
    userPermissions,
    customerId,
    customerBaseUrl,
    dateRange,
}) => {

    // If we build token params object directly without memoization, new object will be created on every render
    // and it will cause endless token hook calls loop, because it reacts to token params changes
    const tokenParams = useMemo(() => ({customerId}), [customerId]);
    const {token: currentToken, params: currentTokenParams} = useServiceToken(userPermissions, Services.Name.customerApiV2, tokenParams);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(true);
    const [userMessagesCount, setUserMessagesCount] = useState<TexterCommon.Metrics.Users.Messages.Count | null>(null);
    // Token hook returns new token not in the same render it was called, 
    // so we need to check if token is already for current customer
    const validToken = currentToken && currentTokenParams.customerId === customerId;

    useEffect(() => {
        if (validToken) {
            setLoading(true);
            setHasError(false);
            new CustomerAPIV2(customerBaseUrl)
                .getMetricsUsersMessagesCount(
                    currentToken,
                    dateRange.from,
                    dateRange.till
                )
                .then(({data}) => {
                    if (data) {
                        setUserMessagesCount(data);
                    } else {
                        setHasError(true);
                    }
                })
                .catch((error) => {
                    // Currently, with our request error handling,
                    // it never really gets here, because it's handled in request interceptor,
                    // but instead it resolves successfully with no response data
                    console.error('Error loading customer users messages count', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [customerBaseUrl, dateRange, currentToken]);

    if (loading || !validToken) {
        return (
            <Spin spinning className="app-loading-wrapper transparent" />
        )
    }

    return (
        <>
            <div className='title'>
                <h1>
                    <LocaleFormatter id='customerMetrics.agentsMessagesCount' />
                </h1>
            </div>
            <div className='content'>
                { !hasError &&
                <Table userMessagesCount={userMessagesCount} />
                }
                { hasError && // TODO: proper styles
                <div>Error loading data. Current instance might not support required API endpoint.</div>
                }
            </div>
        </>
    );
};

export default AgentMessagesCount;
