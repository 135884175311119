import type { Permissions } from '../../../../common';
import type { Tokens } from '../../../../common/services';

import { apiServiceCreateToken } from '@/api/services.api';
import { getJwtExpirationTimeLeft } from '@/utils/getJwtExpirationTimeLeft';

import { Services } from '../../../../common';

const cache = new class {

    maxCacheSize = 10; // To avoid too much iterations when searching

    private tokens: {
        [service in Services.Name]?: {
            token: string;
            // Strict types doesn't work here and not really needed because we not output them but use to compare
            permissions: string[];
            createParams: Record<string, unknown>;
        }[];
    } = {};

    get(service: Services.Name, permissions: string[], createParams: Record<string, unknown>){

        const token = this.tokens[service]?.find(t => {
            if (t.permissions.length !== permissions.length){
                return false;
            }

            if (permissions.sort().join(',') !== t.permissions.sort().join(',')){
                return false;
            }

            for (const key in createParams){
                if (t.createParams[key] !== createParams[key]){
                    return false;
                }
            }

            return true;
        });

        return token ? token.token : null;
    }

    set(service: Services.Name, token: string, permissions: string[], createParams: Record<string, unknown>){
        if (!this.tokens[service]){
            this.tokens[service] = [];
        } else if (this.tokens[service]!.length >= this.maxCacheSize){
            this.tokens[service] = this.tokens[service]!.slice(-(this.maxCacheSize - 1));
        }

        // For some reason in this particular file and class
        // check above for value not being undefined doesn't work,
        // and TS error still happens during build,
        // but not in IDE (at least for me).
        // So here used non-null assertion even it not needed.
        this.tokens[service]!.push({ token, permissions, createParams });
    }

    del(service: Services.Name, token: string){
        if (this.tokens[service]) {
            this.tokens[service] = this.tokens[service]!.filter(t => t.token !== token);
        }
    }
}

/**
 * Obtain access token for a service API access accordind to provided arguments.
 * Tokens are cached (in memory, so untill page reload) and automatically refreshed when needed.
 * 
 * @param userPermissions Permissions that current user has
 * @param service Service name to get access token for
 * @param tokenCreateParams Additional parameters needed to create a token for a particular service
 * @param permissions 
 *  Permissions (scope) of the service to include in the token.
 *  Either could be array of permissions, or special values:
 *   `available` - include all permissions of the service that available to the user.
 *   `none` - include no permissions (Some read endpoints with non-sensitive data may accept such token)
 * @param ttl Token time to live in milliseconds. Default is 30 minutes.
 * @param refreshWhenLeftMS Time in milliseconds before token expiration when it should be refreshed. Default is 5 minutes.
 */
export async function serviceCreateTokenCached<S extends Services.Name, P extends Services.Permissions.ServicePermissionsType[S] = Services.Permissions.ServicePermissionsType[S]>(
    userPermissions: Permissions.Permission[],
    service: S,
    tokenCreateParams: Tokens.CreateParams[S],
    permissions: P[] | 'available' | 'none' = 'available',
    ttl: number = 1000 * 60 * 30,
    refreshWhenLeftMS: number = 1000 * 60 * 5,
){

    let p: P[] = [];

    if (typeof permissions === 'object'){
        p = [...permissions];
    } else if (permissions === 'available'){
        p = Services.Permissions.getServicePermissionsList<P>(
            Services.Permissions.map[service],
            userPermissions,
            true,
        );
    }

    const cachedToken = cache.get(service, p, tokenCreateParams);

    if (cachedToken){
        if (getJwtExpirationTimeLeft(cachedToken) < refreshWhenLeftMS){
            cache.del(service, cachedToken);
        } else {
            return cachedToken;
        }
    }

    const { data } = await apiServiceCreateToken({service, scope: p, ttl, ...tokenCreateParams});

    cache.set(service, data.token, p, tokenCreateParams);

    return data.token;
}
