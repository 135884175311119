import type { TexterCommon } from "../../../../../common";
import type { TableColumnsType } from "antd";

import "./table.less";

import { Card, Statistic, Table as AntdTable } from "antd";

import { useLocale } from "@/locales";

interface Props {
  userMessagesCount: TexterCommon.Metrics.Users.Messages.Count | null;
}

export const Table: React.FC<Props> = ({ userMessagesCount }) => {
  const { formatMessage } = useLocale();

  const columns: TableColumnsType<
    TexterCommon.Metrics.Users.Messages.Count[string]
  > = [
    {
      title: formatMessage({ id: "customerMetrics.table.header.email" }),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: formatMessage({
        id: "customerMetrics.table.header.sessionMessages",
      }),
      dataIndex: "sessionMessages",
      sorter: (a, b) => a.sessionMessages - b.sessionMessages,
    },
    {
      title: formatMessage({
        id: "customerMetrics.table.header.templateMessages",
      }),
      dataIndex: "templateMessages",
      sorter: (a, b) => a.templateMessages - b.templateMessages,
    },
    AntdTable.EXPAND_COLUMN,
    {
      title: formatMessage({
        id: "customerMetrics.table.header.totalMessages",
      }),
      sorter: (a, b) => {
        const totalA = a.sessionMessages + a.templateMessages;
        const totalB = b.sessionMessages + b.templateMessages;

        return totalA - totalB;
      },
      render: (record) => record.sessionMessages + record.templateMessages,
    },
  ];

  return (
    <AntdTable
      columns={columns}
      dataSource={
        userMessagesCount
          ? Object.entries(userMessagesCount).map(([_id, count]) => ({
              _id,
              ...count,
            }))
          : []
      }
      expandable={{
        expandedRowRender: (record) => (
          <div className="agent-messages-count-wrapper">
            <div className="agent-messages-count-title">
              {formatMessage({
                id: "customerMetrics.table.header.perChannel",
              })}
            </div>
            <div className="agent-messages-count-table">
              {record.perChannel.map((channel) => (
                <Card bordered={false} key={channel.accountId}>
                  <Statistic
                    title={formatMessage({
                      id: "customerMetrics.table.header.channel",
                    })}
                    value={channel.channel}
                    formatter={(value) => value}
                  />
                  <Statistic
                    title={formatMessage({
                      id: "customerMetrics.table.header.accountId",
                    })}
                    value={channel.accountId}
                    formatter={(value) => value}
                    className="agent-messages-count-card"
                  />
                  <Statistic
                    title={formatMessage({
                      id: "customerMetrics.table.header.count",
                    })}
                    value={channel.count}
                    className="agent-messages-count-statistic-count"
                  />
                </Card>
              ))}
            </div>
          </div>
        ),
      }}
      sortDirections={["descend", "ascend"]}
      showSorterTooltip={{
        placement: "bottomRight",
      }}
      rowKey="_id"
    />
  );
};
