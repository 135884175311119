import type { Metrics } from '../../../../common/routes';

import { request } from '../request';

export const apiGetChannelsCountByCustomer = (query: Metrics.Channels.GetByCustomerCountQuery) => {
    return request<Metrics.Channels.GetByCustomerCountResponse>(
        'get', 
        '/metrics/channels/count/by-customer', 
        query
    );
};

export const apiGetChannelsCountByType = (query: Metrics.Channels.GetByTypeCountQuery = {}) => {
    return request<Metrics.Channels.GetByTypeCountResponse>(
        'get', 
        '/metrics/channels/count/by-type', 
        query
    );
}
