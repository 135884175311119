import type { Customers } from '../../../common/routes/customers';
import type { Dispatch } from '@reduxjs/toolkit';

import { isNil } from 'lodash';

import {
	apiGetCustomerConfig,
	apiGetCustomerMovingRequirements,
	apiGetCustomers,
	apiGetSchema,
	apiMoveCustomer,
	apiSetupCustomer,
	apiUpdateCustomerConfig,
} from '../api/customers.api';
import { setCustomers, setSchema, unsetCustomers } from './customers.store';

export interface LoadCustomersAsyncParams extends Customers.ListQuery {
	reset?: boolean;
}

export const loadCustomersAsync = (params: LoadCustomersAsyncParams) => {
	return async (dispatch: Dispatch) => {
		const { skip, reset, ...filters } = params;

		if (reset || !skip) {
			dispatch(unsetCustomers());
		}

		const query: Customers.ListQuery = {
			skip: reset || !skip ? 0 : skip,
			...filters,
		};

		const { data } = await apiGetCustomers({ ...query });

		if (data) {
			dispatch(setCustomers({ data }));

			return true;
		}

		return false;
	};
};

export const searchCustomersAsync = (search: string) => {
	return async () => {
		const { data } = await apiGetCustomers({ search });

		if (data) {
			return data.customers;
		}

		return false;
	};
};

export const loadSetupSchemaAsync = (
	instanceId: string,
	partnerId?: string
) => {
	return async (dispatch: Dispatch) => {
		const { data } = await apiGetSchema(instanceId, partnerId);

		if (data) {
			dispatch(setSchema(data));
		}
	};
};

export const setupCustomerAsync = (
	instanceId: string,
	customer: Record<string, any>
) => {
	return async () => {
		const { data } = await apiSetupCustomer(instanceId, customer);

		if (data) {
			return true;
		}

		return false;
	};
};

export const loadCustomerConfigAsync = (
	instanceId: string,
	customerId: string
) => {
	return async () => {
		const { data } = await apiGetCustomerConfig(instanceId, customerId);

		if (data) {
			return data;
		}

		return null;
	};
};

export const updateCustomerConfigAsync = (
	instanceId: string,
	customerId: string,
	config: Record<string, any>
) => {
	return async () => {
		const { data } = await apiUpdateCustomerConfig(
			instanceId,
			customerId,
			config
		);

		if (data) {
			return true;
		}

		return false;
	};
};

export const getCustomerMovingRequirementsAsync = (
	instanceId: string,
	customerId: string,
	toInstanceId: string
) => {
	return async () => {
		const { data } = await apiGetCustomerMovingRequirements(
			instanceId,
			customerId,
			toInstanceId
		);

		if (data) {
			return data;
		}

		return null;
	};
};

export const moveCustomerAsync = (
	instanceId: string,
	customerId: string,
	toInstanceId: string
) => {
	return async () => {
		const { data } = await apiMoveCustomer(
			instanceId,
			customerId,
			toInstanceId
		);

		if (!isNil(data)) {
			return true;
		}

		return false;
	};
};
