export const enUS_title = {
    'title.login': 'Login',
    'title.dashboard': 'Dashboard',
    'title.instances': 'Instances',
    'title.users': 'Users',
    'title.me': 'My Profile',
    'title.users.add': 'Add User',
    'title.users.edit': 'Edit User',
    'title.users.manageApiTokens': 'Manage Api Tokens',
    'title.customers': 'Customers',
    'title.customers.config': 'Customers Configuration',
    'title.customers.move': 'MoveCustomers',
    'title.customers.config.edit': 'Edit Customer Configuration',
    'title.partners': 'Partners',
    'title.partners.profile': 'Partner Profile',
    'title.partners.add': 'Add Partner',
    'title.partners.edit': 'Edit Partner',
    'title.customers.add': 'Setup new Customer',
    'title.customers.access': 'Customer Access',
    'title.permission.route': 'Route Permission',
    'title.permission.button': 'Button Permission',
    'title.permission.config': 'Permission Config',
    'title.account': 'Account',
    'title.notFound': '404',
    'title.websiteChat': 'Website Chat',
    'title.deployWebsiteChat': 'Deploy Website Chat',
    'title.customerMetrics': 'Customer Metrics',
    'title.customerMetrics.agentsMessagesCount': 'Agents messages count',
};
