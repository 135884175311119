import type { WrapperProps } from "./components/Wrapper";
import type { DateRange } from "@/interface/dateRange";
import type { TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocale } from "@/locales";
import { setDateRange } from "@/stores/filters.store";

import Wrapper from "./components/Wrapper";

const { RangePicker } = DatePicker;

interface Props extends WrapperProps {
  /**
   * Callback to be called when a date range is selected
   */
  onDateRangeChange?: (dateRange: DateRange) => void;
}

/**
 * Wrapper component that provides a date range picker.
 * Data is stored in the redux store and can be accessed from it,
 * but optionally a callback can be provided to get the selected date range.
 *
 **/
const WithDateRangePicker: React.FC<Props> = ({
  children,
  onDateRangeChange,
  ...wrapperProps
}) => {
  const { formatMessage } = useLocale();
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.filters);

  useEffect(() => {
    if (onDateRangeChange) {
      onDateRangeChange(dateRange);
    }
  }, [dateRange, onDateRangeChange]);

  const rangePresets: TimeRangePickerProps["presets"] = [
    {
      label: formatMessage({
        id: "filters.dateRangePicker.last7Days",
      }),
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: formatMessage({
        id: "filters.dateRangePicker.last30Days",
      }),
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: formatMessage({
        id: "filters.dateRangePicker.last60Days",
      }),
      value: [dayjs().add(-60, "d"), dayjs()],
    },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const dateRange = {
        from: dayjs(dates[0]).unix() * 1000,
        till: dayjs(dates[1]).unix() * 1000,
      };

      dispatch(setDateRange(dateRange));
    }
  };

  return (
    <>
      <Wrapper {...wrapperProps}>
        <RangePicker
          presets={rangePresets}
          value={[dayjs(dateRange.from), dayjs(dateRange.till)]}
          onChange={onRangeChange}
          disabledDate={(current) => {
            return (
              current > dayjs().endOf("day") ||
              current < dayjs().add(-90, "d").startOf("day")
            );
          }}
          format="YYYY-MM-DD"
          className="date-range-picker-wrapper"
          allowClear={false}
        />
      </Wrapper>
      {children}
    </>
  );
};

export default WithDateRangePicker;
