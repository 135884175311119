import type { PrivateRouteProps } from './PrivateRoute';
import type { FC, ReactElement } from 'react';
import type { SetOptional } from 'type-fest';

import { useIntl } from 'react-intl';

import PrivateRoute from './PrivateRoute';

export type WrapperRouteProps = SetOptional<PrivateRouteProps, 'auth'> & {
    /** document title locale id */
    titleId: string;
};

const WrapperRouteComponent: FC<WrapperRouteProps> = ({ titleId, auth, ...props }) => {
    const { formatMessage } = useIntl();

    if (titleId) {
        document.title = formatMessage({
            id: titleId,
        });
    }

    return auth ? <PrivateRoute {...props} auth={auth} /> : (props.element as ReactElement);
};

export default WrapperRouteComponent;
